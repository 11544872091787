<script setup lang="ts">
import {ChevronRightIcon} from "@heroicons/vue/20/solid";

const { t } = useI18n()

const videoLoaded = ref(false);

async function onVideoLoaded() {
  videoLoaded.value = true;
}

const { data: lastArticles } = useFetch(
    '/api/articles/getAllArticles', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        page: 1,
      },
    });


function diffForHumans(dateString: string) {
  const now = new Date();
  const targetDate = new Date(dateString);
  const differenceInSeconds = Math.floor((now.getTime() - targetDate.getTime()) / 1000);

  if (differenceInSeconds < 60)
    return t('admin.users.a-few-seconds-ago');
  else if (differenceInSeconds < 3600)
    return `${t('admin.users.first-part-minute')} ${Math.floor(differenceInSeconds / 60)} ${t('admin.users.second-part-minute')}`;
  else if (differenceInSeconds < 86400)
    return `${t('admin.users.first-part-hour')} ${Math.floor(differenceInSeconds / 3600)} ${t('admin.users.second-part-hour')}`;
  else
    return `${t('admin.users.first-part-day')} ${Math.floor(differenceInSeconds / 86400)} ${t('admin.users.second-part-day')}`;
}
</script>

<template>
  <div>
    <img v-show="!videoLoaded" src="/assets/boat.webp" class="top-0 bottom-0 left-0 right-0 -z-1 h-screen w-screen object-cover relative" alt="Loading image">
    <video v-show="videoLoaded" @loadeddata="onVideoLoaded" autoplay muted loop class="top-0 bottom-0 left-0 right-0 -z-1 h-screen w-screen object-cover relative">
      <source src="/assets/video/boat.mp4" type="video/mp4" />
    </video>
    <div class="hidden absolute top-32 bottom-0 left-0 right-10 sm:flex justify-end">
      <div class="text-white text-xl sm:text-3xl font-bold text-right">
        <h2>{{ $t('home.title1') }}</h2>
        <h2>{{ $t('home.title2') }}</h2>
        <h2>{{ $t('home.title3') }}</h2>
        <div class="bg-white ml-auto h-1.5 w-3/4 mt-3"></div>
      </div>
    </div>
    <div class="absolute top-0 bottom-20 left-0 right-0 flex justify-center items-end">
      <div class="text-white text-lg text-center flex flex-col space-y-8 sm:space-y-0 sm:flex-row sm:space-x-16">
        <NuxtLink :to="localePath('/yachts/sale')">
          <button class="text-white hover:before:bg-red uppercase relative overflow-hidden border border-white bg-transparent w-60 h-14 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-white before:transition-all before:duration-500 hover:text-ocean-1000 hover:before:left-0 hover:before:w-full"><span class="relative z-10 tracking-widest">{{ $t('home.button-sale') }}</span></button>
        </NuxtLink>
        <NuxtLink :to="localePath('/yachts/rent')">
          <button class="text-white hover:before:bg-red uppercase relative overflow-hidden border border-white bg-transparent w-60 h-14 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-white before:transition-all before:duration-500 hover:text-ocean-1000 hover:before:left-0 hover:before:w-full"><span class="relative z-10 tracking-widest">{{ $t('home.button-charter') }}</span></button>
        </NuxtLink>
      </div>
    </div>
  </div>
  <div>
    <div class="h-[180vh] sm:h-screen bg-ocean-1050 flex items-center flex-col justify-around">
      <div class="sm:w-4/6 w-5/6 sm:mx-auto sm:pt-28 text-center text-white text-xl sm:text-3xl relative">
        <div class="sm:w-2/5 w-full h-64 bg-gradient-to-r from-ocean-800 to-gray-500 rounded-full blur-2xl mx-auto absolute -top-20 sm:top-10 sm:left-[30%] z-0 animate-[wiggle_3s_ease-in-out_infinite]"></div>
        <div class="relative z-10 font-semibold">
          <h1 class="text-2xl sm:text-4xl">{{ $t('home.subtitle') }}</h1>
          <h2 class="mt-2 text-3xl">{{ $t('home.subtitle-description') }}</h2>
        </div>
      </div>
      <div class="relative w-full sm:w-4/6 mx-auto sm:pt-28 z-10 h-2/3">
        <div class="flex flex-col sm:flex-row justify-between items-start space-y-12 sm:space-y-0">
          <div class="w-2/3 mx-auto sm:mx-0 sm:w-1/4 shadow-lg shadow-ocean-950 relative" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
            <NuxtLink :to="localePath('/yachts/sale')">
              <div class="hover:scale-110 transition ease-in-out cursor-pointer">
                <NuxtImg src="/assets/home-sale.webp" alt="boat" class="object-center object-cover w-full sm:h-96 h-64 brightness-90" />
                <div class="absolute bottom-0 left-0 sm:h-1/6 h-1/4">
                  <div class="flex flex-col pl-6">
                    <span class="text-white text-xl">{{ $t('home.yachts-for-sale') }}</span>
                    <span class="text-white font-semibold text-sm">{{ $t('home.view-more') }} ➜</span>
                  </div>
                </div>
              </div>
            </NuxtLink>
          </div>
          <div class="w-2/3 mx-auto sm:mx-0 sm:w-1/4 shadow-lg shadow-ocean-950 relative" data-aos="fade-up" data-aos-delay="600" data-aos-once="true">
            <NuxtLink :to="localePath('/yachts/rent')">
              <div class="hover:scale-110 transition ease-in-out cursor-pointer">
                <NuxtImg src="/assets/home-charter.webp" alt="boat" class="object-center object-cover w-full sm:h-96 h-64 brightness-90" />
                <div class="absolute bottom-0 left-0 sm:h-1/6 h-1/4">
                  <div class="flex flex-col pl-6">
                    <span class="text-white text-xl">{{ $t('home.charter-a-yacht') }}</span>
                    <span class="text-white font-semibold text-sm">{{ $t('home.view-more') }} ➜</span>
                  </div>
                </div>
              </div>
            </NuxtLink>
          </div>
          <div class="w-2/3 mx-auto sm:mx-0 sm:w-1/4 shadow-lg shadow-ocean-950 relative" data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
            <div class="cursor-not-allowed">
              <NuxtImg src="/assets/home-crew.webp" alt="boat" class="object-center object-cover w-full sm:h-96 h-64 brightness-90 grayscale" />
              <div class="absolute bottom-0 left-0 sm:h-1/6 h-1/4">
                <div class="flex flex-col pl-6">
                  <span class="text-white text-xl">{{ $t('home.crew-management') }}</span>
                  <span class="text-white font-semibold text-sm">{{ $t('home.soon') }} ➜</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-screen flex flex-col-reverse sm:flex-row-reverse">
      <div class="sm:w-1/2 bg-white grow flex justify-center items-center">
        <div class="w-5/6 py-12 sm:py-0 sm:w-4/6 text-left">
          <h1 class="text-black text-2xl font-bold">{{ $t('home.build-title') }}</h1>
          <div class="bg-ocean-950 h-1.5 w-40 mt-3"></div>
          <div class="mt-8 sm:mt-16 text-lg sm:text-xl">
            <p>{{ $t('home.build-description-first') }}</p>
            <p class="mt-4">{{ $t('home.build-description-second') }}</p>
          </div>
          <div class="flex flex-col space-y-2 sm:space-y-6 mt-8 sm:mt-20 text-ocean-950 font-bold">
            <NuxtLink :to="localePath('/')" class=""><span class="relative z-10">{{ $t('home.build-button-build') }} →</span></NuxtLink>
            <NuxtLink :to="localePath('/yacht-management')" class=""><span class="relative z-10">{{ $t('home.build-button-management') }} →</span></NuxtLink>
          </div>
        </div>
      </div>
      <div class="sm:h-screen-1/2 sm:w-1/2">
        <NuxtImg src="/assets/build.webp" alt="latest waves of news" data-aos="zoom-in" data-aos-once="true" class="object-cover sm:h-screen" />
      </div>
    </div>
    <div class="h-screen flex flex-col-reverse sm:flex-row">
      <div class="sm:w-1/2 bg-ocean-1050 grow flex justify-center items-center">
        <div class="w-5/6 h-auto py-12 sm:py-0 sm:w-4/6 sm:h-4/6 text-left">
          <h1 class="text-white text-2xl font-bold">{{ $t('home.news-title') }}</h1>
          <div class="bg-ocean-950 h-1.5 w-40 mt-3"></div>
          <div class="divide-gray-100 mt-4 sm:mt-16 divide-y" v-if="lastArticles.total != 0">
            <div v-for="(item, index) in lastArticles.articles" data-aos="fade-right" :data-aos-delay="(index * 200) + 300" data-aos-once="true" class="p-4 cursor-pointer hover:bg-gray-50/10">
              <NuxtLink :to="localePath(`/news/${item._id}`)" class="flex justify-between">
                <div>
                  <h2 class="text-white text-sm sm:text-lg font-semibold">{{ item.title }}</h2>
                  <p class="text-white text-xs sm:text-sm">{{ diffForHumans(item.createdAt) }}</p>
                </div>
                <ChevronRightIcon class="h-8 w-8 sm:h-12 sm:w-12 text-white" aria-hidden="true" />
              </NuxtLink>
            </div>
          </div>
          <div class="mt-4 sm:mt-16" v-else>
            <h2 class="text-white text-sm sm:text-lg font-semibold">{{ $t('home.no-news') }}</h2>
          </div>
          <span class="flex items-center justify-center mt-4 sm:mt-16" v-if="lastArticles.total != 0">
            <NuxtLink :to="localePath('/news')">
              <button class="text-white hover:before:bg-white uppercase relative overflow-hidden border border-white bg-transparent px-16 py-2 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-white before:transition-all before:duration-500 hover:text-ocean-1000 hover:before:left-0 hover:before:w-full"><span class="relative z-10">{{ $t('home.news-more') }}</span></button>
            </NuxtLink>
          </span>
        </div>
      </div>
      <div class="sm:h-screen-1/2 sm:w-1/2">
        <NuxtImg src="/assets/news.webp" alt="latest waves of news" data-aos="zoom-in" data-aos-once="true" class="object-cover sm:h-screen" />
      </div>
    </div>
    <UtilsSubscribeNewsletter />
  </div>
</template>
